import React, { useEffect } from 'react';
import NextLink from 'next/link';
import useSWR from 'swr';
import {
	MenuButton, MenuList,
	Flex, Icon, Tag, Stack, Divider, Button,
	Link as ChakraLink, Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import {
	faBookmark,
} from '@fortawesome/free-solid-svg-icons';

export default function MenuFavorites({ isRetrived, setIsRetrived }) {
	// 一開始都不要去拿資料 之後 mutate 拿一次
	const { data: favorites, mutate } = useSWR('/api/v2/collection/favorite', {
		revalidateOnFocus: false,
		revalidateOnMount: false,
		revalidateOnReconnect: false,
		refreshWhenOffline: false,
		refreshWhenHidden: false,
		refreshInterval: 0,
	});

	useEffect(() => {
		if (isRetrived) {
			mutate();
		}
	}, [isRetrived]);

	return (
		<>
			<MenuButton as="Button" onClick={() => setIsRetrived(true)}>
				<Flex alignItems="center" pr={3} color="textGray" mr={3}>
					<Text mr={2} fontSize="sm">收藏庫</Text>
					<Icon as={Fa} icon={faBookmark} size="1x" />
				</Flex>
			</MenuButton>
			<MenuList bgColor="white" p={4} width="400px">
				{favorites?.length > 0 ? (
					<>
						<Flex>
							<Text fontSize="sm">已收藏的資料集</Text>
							<Tag ml={2} size="sm">{favorites?.length}</Tag>
						</Flex>
						<Stack mt={6} spacing={3}>
							{favorites?.map((f, index) => (
								<>
									{index <= 2 && (
										<>
											<Text fontSize="sm">{f.name}</Text>
											<Divider />
										</>
									)}
								</>
							))}
						</Stack>
						<Flex mt={2} justifyContent="flex-end">
							<NextLink passHref href="/collections/favorite">
								<Button size="sm" as={ChakraLink} colorScheme="red" bgColor="pryRed">
									<Text color="white">檢視全部</Text>
								</Button>
							</NextLink>
						</Flex>
					</>
				) : (
					<Flex h="80px" justifyContent="center" alignItems="center">
						<Text fontSize="xs">還沒有收藏任何資料集！</Text>
					</Flex>
				)}
			</MenuList>
		</>
	);
}
